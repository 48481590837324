import React, { useState, useEffect, useRef } from "react";

const Queries = ({currentData, onClick, data}) => {
    const queryEndRef = useRef(null);
    const [currentId, setCurrentId] = useState(0);

    const scrollToBottom = () => queryEndRef.current.scrollIntoView();

    useEffect(scrollToBottom, []);

    useEffect(() => {
        let {level} = currentData;
        if(!level) {
            return;
        }

        let tempId;
        tempId = data[level];
        if(tempId !== undefined) {
            setCurrentId(tempId);
        }

    }, [data]);

    return (
        <div className='App-level'>
            <div className='App-row'>
            <img src='img/robot.png' className='App-bot' alt='bot'/>
            <p className='App-para'>{currentData.text[0].text}</p>
            </div>
            <div style={{display: 'flex', flexWrap: 'wrap', padding: '5px'}}>{currentData.btnArray.map((x) => <button key={x.id} className={(currentId === x.id) ? 'App-query-clicked':'App-query'} onClick={() => {onClick(x, currentData.level)}}>{x.query}</button>)}</div>
            <div ref={queryEndRef}/>
        </div>
    );
};

export default Queries;